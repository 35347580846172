import React from 'react';
import { Instagram, Facebook, Twitter, YouTube } from '@material-ui/icons';

const Footer = () => {
  return (
    <footer>
      <div className="footer-titles">
        <h4>KEVIN MUICO</h4>
        <h5>Theology of the Body Speaker</h5>
        <span>Copyright 2019</span>
        <a
          className="cct"
          href="mailto:catholic.creatives.to@gmail.com?subject=From Kevin Muico Site"
        >
          Powered by Catholic Creatives Toronto
        </a>
      </div>
      <ul className="footer-social">
        <li>
          <a
            href="https://www.instagram.com/kevinmuico"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram fontSize="large" color="primary" />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/KevinMuico"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook fontSize="large" color="primary" />
          </a>
        </li>
        <li>
          <a
            href="https://www.twitter.com/kevinmuico"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter fontSize="large" color="primary" />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/kevinmuico"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTube fontSize="large" color="primary" />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
