import React from 'react';
import { Link } from 'gatsby';

import Footer from '../Footer';

export default ({ active, children }) => {
  return (
    <>
      <header>
        <div className="header-logo">
          <a href="/">
            KEVIN
            <br />
            MUICO
          </a>
        </div>
        <nav>
          <span>
            {active === 'blog' ? (
              <a href="/#about">About</a>
            ) : (
              <Link to="#about">About</Link>
            )}
          </span>
          <span>
            {active === 'blog' ? (
              <a href="/#events">Speaking</a>
            ) : (
              <Link to="#events">Speaking</Link>
            )}
          </span>
          <span>
            {active === 'blog' ? (
              <Link to="/blog">Blog</Link>
            ) : (
              <a href="/blog">Blog</a>
            )}
          </span>
          {/* <span>
          <a href="#">Contact</a>
        </span> */}
        </nav>
      </header>
      {children}
      <Footer />
    </>
  );
};
